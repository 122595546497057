import Head from "next/head";
import React from "react";

export default function StructuredData({ data }) {
	return (
		<Head>
			<script
				async
				key="structured-data"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
			/>
		</Head>
	);
}
