import React, { Component } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import { withRouter } from "next/router";
import Loading from "./../src/components/Loading";
import AuthChecker from "../src/common/AuthChecker";
import StructuredData from "../src/components/StructuredData";
import { seoFunct } from "../src/config/next-seo.config";
import { NextSeo } from "next-seo";
import Cookies from "js-cookie";

const HomeComponent = dynamic(import("../src/pages/Home/Home"), {
	loading: () => <Loading />
});

class Home extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let value = this.context;
	}

	render() {
		return (
			<>
				<NextSeo {...seoFunct("homeSeo")} />
				<StructuredData
					data={{
						"@context": "https://schema.org",
						"@type": "Organization",
						name: "KonfHub",
						url: "https://konfhub.com/",
						logo: "https://konfhub.com/img/logo.svg",
						contactPoint: {
							"@type": "ContactPoint",
							telephone: "",
							contactType: "customer service"
						},
						sameAs: [
							"https://www.facebook.com/konfhub/",
							"https://www.twitter.com/konfhub",
							"https://www.youtube.com/c/KonfHubTech",
							"https://www.linkedin.com/in/konfhub/",
							"https://instagram.com/konfhub_tech_conference"
						]
					}}
				/>
				<HomeComponent
					newUserLogin={
						this.props.router.isReady ? this.props.router.query.signIn : false
					}
				/>
				{/*<Outage/>*/}
			</>
		);
	}
}
export async function getServerSideProps(ctx) {
	Cookies.remove('authToken');
	await AuthChecker(ctx, false);
	return { props: {} };
}

export default withRouter(Home);
